import classnames from 'classnames'
import React, { FC, PropsWithChildren } from 'react'

import { Container, HeroWrapper } from './Hero.styles'
import { ScrollHint } from './ScrollHint'

type Props = {
  align: string
  desktopBackground: string
  mobileBackground: string
  className?: string
  overlayGradient?: boolean
  scrollHint?: boolean
  scrollHintAnchor?: string
  marginBottom?: string
  id?: string
  hiddenAt?: string
  mediaItems: Record<string, unknown>[]
  bottomPadding?: number
}

export const Hero: FC<PropsWithChildren<Props>> = ({
  children,
  desktopBackground, // Tablet+ Image / md / min-width: 768px
  mobileBackground, // Primary Image / xs / min-width: 0px
  className,
  overlayGradient,
  scrollHint,
  scrollHintAnchor,
  marginBottom,
  id,
  hiddenAt,
  bottomPadding,
}) => {
  const breakpointsImageEntries = {
    md: JSON.parse(desktopBackground),
    xs: JSON.parse(mobileBackground),
  }

  return (
    <HeroWrapper
      id={id}
      hiddenAt={hiddenAt}
      className={classnames([className, 'hero-wrapper'])}
      mobileImage={breakpointsImageEntries.xs}
      desktopImage={breakpointsImageEntries.md}
      overlay={overlayGradient}
      marginBottom={marginBottom}
      bottomPadding={bottomPadding}
    >
      {scrollHint && scrollHintAnchor && (
        <ScrollHint scrollHintAnchor={scrollHintAnchor} />
      )}

      <Container className="container">{children}</Container>
    </HeroWrapper>
  )
}
