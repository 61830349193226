import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { hideBlockAt, marginBreakpoints } from '../../../../utils/commonAttrs'
import { acceptableFileTypesForWebp } from '../../../../utils/fileTypesForWebP'
import { ImageData } from '../../../../types/Image'
import { fromBreakpoint } from '../../../../utils/style/breakpoint'

export const overlayStyle = css`
  &:after {
    background: ${({ theme }) => theme.tokens.interface.neutral.strong};
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    top: 0;
    z-index: 0;
    opacity: 0.7;
  }

  ${fromBreakpoint('md')} {
    &:after {
      opacity: 1;
      background: linear-gradient(
        to right,
        ${({ theme }) => `${theme.tokens.interface.neutral.strong}BF`} 0,
        ${({ theme }) => `${theme.tokens.interface.neutral.strong}BF`} 33%,
        ${({ theme }) => `${theme.tokens.interface.neutral.strong}00`} 66%,
        ${({ theme }) => `${theme.tokens.interface.neutral.strong}00`} 100%
      );
    }
  }
`

type WrapperProps = {
  overlay?: boolean
  desktopImage?: ImageData
  mobileImage?: ImageData
  marginBottom?: string
  hiddenAt?: string
  bottomPadding?: number
}

function breakpointImageSet(imageData: ImageData): FlattenSimpleInterpolation {
  if (acceptableFileTypesForWebp.test(imageData.subtype))
    return css`
      background-image: url(${imageData.url});
      background-image: image-set(
        url(${imageData.sizes.full.url.concat('.webp')}) type('image/webp'),
        url(${imageData.sizes.full.url}) type('${imageData.mime}')
      );
  `

  return css`
    background-image: url(${imageData.url});
  `
}

export const HeroWrapper = styled.section<WrapperProps>`
  padding: 80px 0 ${({ bottomPadding }) => `${bottomPadding ?? 80}px`} 0;
  position: relative;
  ${({ overlay }) => (overlay ? overlayStyle : '')}
  ${({ marginBottom }) =>
    marginBottom && marginBreakpoints({ marginBottom }, 0)}
  ${({ hiddenAt }) => hiddenAt && hideBlockAt({ hiddenAt })}
  ${({ mobileImage }) => (mobileImage ? breakpointImageSet(mobileImage) : '')}
  background-size: cover;
  background-position: center center;

  ${fromBreakpoint('md')} {
    padding: 120px 0 ${({ bottomPadding }) => `${bottomPadding ?? 120}px`} 0;

    ${({ desktopImage }) =>
      desktopImage
        ? css`
            ${breakpointImageSet(desktopImage)}
          `
        : ''}
  }
  
  @media screen and (min-width: 1920px) {
    padding: 180px 0 ${({ bottomPadding }) => `${bottomPadding ?? 180}px`} 0;
  }
`

export const Container = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  z-index: 10;
`
