import SmoothAnchorLink from 'react-anchor-link-smooth-scroll'
import styled from 'styled-components'

import { spacing } from '../../../../utils/ui/spacing'

export const Container = styled.div`
  display: block;
  position: absolute;
  bottom: 16px;
  text-align: center;
  width: 100%;
  z-index: 1;
`

export const ScrollLink = styled(SmoothAnchorLink)`
  text-align: center;
  text-decoration: none;
  color: ${({ theme }) => theme.tokens.text.label.reversed};
  font-family: ${({ theme }) => theme.typography.font.family.title};
  font-size: 12px;
`

export const ScrollImage = styled.img`
  display: block;
  width: 24px;
  height: 26px;
  margin-top: ${spacing('md')};
  margin-left: auto;
  margin-right: auto;
`
