import { withPrefix } from 'gatsby'
import React, { FC } from 'react'

import lang, * as l10n from '../../../../utils/l10n'
import AnimateOnScroll from '../../../common/AnimateOnScroll'
import { Container, ScrollImage, ScrollLink } from './ScrollHint.styles'

type Props = {
  scrollHintAnchor: string
}

export const ScrollHint: FC<Props> = ({ scrollHintAnchor }) => (
  <Container>
    <AnimateOnScroll animationType="fadeIn" animationDelay="1500" animationEasing="linear">
      <ScrollLink href={`#${scrollHintAnchor}`}>
        {l10n.scroll_hint[lang as keyof typeof l10n.scroll_hint]}
        <ScrollImage
          className="scroll-hint"
          src={withPrefix('/scroll-arrows.svg')}
          alt="Scroll hint"
        />
      </ScrollLink>
    </AnimateOnScroll>
  </Container>
)
